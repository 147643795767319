import {
  Flex,
  Text,
  Sidebar,
  useSidebar,
  SidebarContent,
  Button,
} from "@appsmith/wds";
import type { SidebarProps } from "@appsmith/wds";
import React, { useState, forwardRef } from "react";

import styles from "./styles.module.css";

interface ChatCitationSidebarProps extends SidebarProps {
  title: string;
  children: React.ReactNode;
}

export const _ChatCitationSidebar = (props: ChatCitationSidebarProps) => {
  const { children, title, ...rest } = props;
  const { isMobile, state } = useSidebar();
  const [expanded, setExpanded] = useState(false);

  return (
    <Sidebar
      className={styles.citationSidebar}
      // TODO: This should be part of the sidebar functionality.
      data-expanded={expanded}
      side="end"
      {...rest}
    >
      <SidebarContent>
        <Flex direction="column" height="100%" isInner>
          {/*TODO: This should be a WDS Sidebar header*/}
          <Flex
            alignItems="center"
            className={styles.citationSidebarHeader}
            height="sizing-14"
            isInner
            justifyContent="space-between"
            padding="spacing-2"
          >
            {state === "expanded" && <Text lineClamp={1}>{title}</Text>}
            {state === "expanded" && !isMobile && (
              <Button
                color="neutral"
                icon={
                  expanded ? "arrows-diagonal-minimize" : "arrows-diagonal-2"
                }
                onPress={() => setExpanded(!expanded)}
                variant="ghost"
              />
            )}
          </Flex>
          <Flex className={styles.citationSidebarContent}>{children}</Flex>
        </Flex>
      </SidebarContent>
    </Sidebar>
  );
};

export const ChatCitationSidebar = forwardRef(_ChatCitationSidebar);
